import React, { useContext } from "react";
import { Context } from ".";

export default function MenuIcon() {
  const { setIsOpen } = useContext(Context);
  return (
    <div
      onClick={() => {
        setIsOpen(true);
      }}
      className="w-10 h-10 rounded-full flex items-center justify-center bg-primary cursor-pointer"
    >
      <svg
        id="SVGDoc"
        width={20}
        height={13}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 20 13"
      >
        <g>
          <g>
            <g>
              <path
                d="M0,1c0,-0.55228 0.44772,-1 1,-1h18c0.55228,0 1,0.44772 1,1v1c0,0.55228 -0.44772,1 -1,1h-18c-0.55228,0 -1,-0.44772 -1,-1z"
                fill="#ffffff"
                fillOpacity={1}
              />
            </g>
            <g>
              <path
                d="M2,6c0,-0.55228 0.44772,-1 1,-1h14c0.55228,0 1,0.44772 1,1v1c0,0.55228 -0.44772,1 -1,1h-14c-0.55228,0 -1,-0.44772 -1,-1z"
                fill="#ffffff"
                fillOpacity={1}
              />
            </g>
            <g>
              <path
                d="M5,11c0,-0.55228 0.44772,-1 1,-1h8c0.55228,0 1,0.44772 1,1v1c0,0.55228 -0.44772,1 -1,1h-8c-0.55228,0 -1,-0.44772 -1,-1z"
                fill="#ffffff"
                fillOpacity={1}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
