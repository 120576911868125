import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import config from "./config";
import HomeIcon from "./HomeIcon";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import { motion } from "framer-motion";
import useIntersectionObserver from "../../useIntersectionObserver";

export default function DesktopMenu({ location = {} }) {
  const [isFixed, setIsFixed] = useState(false);
  const triggerRef = useRef();
  useIntersectionObserver({
    target: triggerRef,
    onIntersect: () => {
      setIsFixed(false);
    },
    onLeave: () => {
      setIsFixed(true);
    },
  });
  return (
    <>
      <div
        className="hidden lg:block"
        ref={triggerRef}
        css={css`
          height: ${isFixed ? "48px" : "0px"};
        `}
      ></div>
      <div
        className={`bg-primary py-2 font-bench hidden lg:block ${
          isFixed ? "fixed top-0 w-full z-50" : ""
        }`}
      >
        <div className="container ">
          <div className="flex   items-center    ">
            <div className="flex-grow flex flex-wrap   flex-shrink-0  items-center text-white    space-x-3">
              {location.pathname !== "/" && (
                <Link to="/" className=" inline-block">
                  <HomeIcon />
                </Link>
              )}

              {config.map((item, index) => (
                <React.Fragment key={index}>
                  {item.children ? (
                    <Dropdown item={item} />
                  ) : (
                    <Link
                      to={item.value}
                      className="uppercase font-bench  leading-none"
                      css={css`
                      font-size: ${"0.95rem"};
                    `}
                      key={item.value}
                    >
                      {item.label}
                    </Link>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="flex-shrink-0   justify-self-end">
              <Link
                to="/submit-story-2024"
                className="bg-accent leading-none inline-flex items-center justify-center px-6 py-2  text-white rounded-full relative"
              >
                SHARE YOUR STORY TO WIN{" "}
                <MdKeyboardArrowRight className="absolute right-0  inline mr-1v2"    
                css={css`
                top:8px;
                `} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Dropdown({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const onClickOutside = (e) => {
      const isOutside = !e.target.closest(".dropdown");
      if (isOutside) {
        setIsOpen(false);
      }
    };
    if (typeof window !== "undefined") {
      window.addEventListener("click", onClickOutside);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("click", onClickOutside);
      }
    };
  }, []);
  return (
    <motion.div
      className="dropdown inline-block uppercase font-bench cursor-pointer relative"
      css={css`
      font-size: ${"0.95rem"};
    `}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <span className="leading-none ">{item.label}</span>
      {isOpen && item.children && (
        <div
          className="dropdown grid grid-cols-2 gap-y-2 gap-x-4 absolute bg-primary p-2 z-40 shadow "
          css={css`
            top: 2rem;
            width: 25rem;
          `}
        >
          {item.children.map((item) => (
            <Link
              to={item.value}
              className="uppercase font-bench  leading-none  hover:bg-black px-4 py-2"
              key={item.value}
            >
              {item.label}
            </Link>
          ))}
        </div>
      )}
    </motion.div>
  );
}
