import { Link } from "gatsby";
import React from "react";

export default function HomeIcon() {
  return (
    <Link
      to="/"
      className="w-10 h-10 rounded-full flex items-center justify-center bg-primary"
    >
      <svg
        id="SVGDoc"
        width={20}
        height={20}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 20 20"
      >
        <g>
          <g>
            <g>
              <path
                d="M10.50686,1.9493l7.36796,5.99822c0.1936,0.15762 0.307,0.3971 0.307,0.65115v8.70083c0,0.46185 -0.36656,0.83232 -0.81388,0.83232h-14.73582c-0.44735,0 -0.81394,-0.37049 -0.81394,-0.83232v-8.70083c0,-0.254 0.11342,-0.4935 0.30709,-0.65118l7.36786,-5.99817c0.29667,-0.24152 0.71709,-0.24152 1.01373,-0.00002zM8.34526,0.53929l-7.36791,5.99822c-0.61854,0.50359 -0.97735,1.26127 -0.97735,2.06116v8.70083c0,1.46171 1.17629,2.6505 2.63212,2.6505h14.73582c1.45583,0 2.63206,-1.18879 2.63206,-2.6505v-8.70083c0,-0.79991 -0.35876,-1.55757 -0.97726,-2.06114l-7.36798,-5.99823c-0.96512,-0.78573 -2.34431,-0.78573 -3.3095,-0.00001z"
                fill="#ffffff"
              />
            </g>
            <g>
              <path
                d="M10.10796,10.78653c1.12304,0.06361 2.03591,1.06981 2.03591,2.32627l-0.00032,5.01387h-4.2977l0.00032,-5.01387c0,-1.29836 0.97469,-2.32946 2.14881,-2.32946zM9.99997,8.95c-2.22184,0 -3.99997,1.87411 -3.99997,4.15963v5.9237c0,0.50626 0.41328,0.91667 0.92308,0.91667h6.15384c0.5098,0 0.92308,-0.41041 0.92308,-0.91667v-5.9237c0,-2.28551 -1.77818,-4.15963 -4.00003,-4.15963z"
                fill="#ffffff"
              />
            </g>
          </g>
        </g>
      </svg>
    </Link>
  );
}
