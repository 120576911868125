import { motion } from "framer-motion";
import { Link } from "gatsby";
import React, { useState } from "react";
import MenuIcon from "./MenuIcon";
import config from "./config";
import HomeIcon from "./HomeIcon";
import { useRef } from "react";
import useIntersectionObserver from "../../useIntersectionObserver";
import { css } from "@emotion/core";

export default function MenuControls({ location = {} }) {
  const [isFixed, setIsFixed] = useState(false);
  const triggerRef = useRef();
  useIntersectionObserver({
    target: triggerRef,
    onIntersect: () => {
      setIsFixed(false);
    },
    onLeave: () => {
      setIsFixed(true);
    },
  });
  return (
    <>
      <div
        ref={triggerRef}
        css={css`
          height: ${isFixed && location.pathname !== "/" ? "0px" : "0px"};
        `}
      ></div>
      <div className={isFixed ? "fixed top-0 w-full z-50" : ""}>
        <div
          className={`${
            location.pathname !== "/" ? "" : "absolute"
          } z-40 w-full hidden tablet:block `}
        >
          <div className="container pt-3 flex justify-between items-center cursor-pointer">
            {location.pathname !== "/" ? <HomeIcon /> : <div />}
            <MenuIcon />
          </div>
        </div>
      </div>
    </>
  );
}
