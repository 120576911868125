import kebabCase from "lodash/kebabCase";

const config = [
  {
    label: "WINALOT",
    value: "/" + kebabCase("why-choose-winalot"),
  },
  { label: "ALL DOGS",
    value: "/" + kebabCase("All Dogs"),
  },
  {
    label: "Newest",
    value: "/" + kebabCase("Newest Dogs"),
  },
  {
    label: "Puppy",
    value: "/" + kebabCase("Puppy"),
  },
  {
    label: "Adult",
    value: "/" + kebabCase("Adult"),
  },
  {
    label: "Senior",
    value: "/" + kebabCase("Senior"),
  },
  {
    label: "Small",
    value: "/" + kebabCase("Small"),
  },
  {
    label: "Class of 19/23",
    value: "/" + kebabCase("Class of 19/23"),
  },
  {
    label: "Dog of the Year",
    value: "/" + kebabCase("meet-dora"),
  },
];
export default config;