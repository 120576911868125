import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useContext, useState } from "react";
import config from "./config";
import { css } from "@emotion/core";
import CloseArrow from "./CloseArrow";
import { Context } from ".";
import GatsbyImage from "gatsby-image";
import { MdKeyboardArrowRight } from "react-icons/md";

export default function MobileNavigation() {
  const [locationIsOpen, setLocationIsOpen] = useState(0);
  const { isOpen, setIsOpen } = useContext(Context);

  return (
    <>
      <motion.div
        initial={{ x: "100%" }}
        animate={
          isOpen
            ? locationIsOpen
              ? {
                  x: "-20%",
                  transition: {
                    duration: 0.3,
                    ease: "easeIn",
                  },
                }
              : {
                  x: 0,
                  transition: {
                    duration: 0.3,
                    ease: "easeOut",
                  },
                }
            : {
                x: "100%",
                transition: {
                  duration: 0.3,
                  ease: "easeIn",
                },
              }
        }
        className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-primary text-white tablet:block hidden overflow-y-scroll overflow-x-hidden"
        css={css`
          z-index: 100;
        `}
      >
        <Winalot />
        <div className="p-4">
          <CloseArrow
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="flex flex-col p-4 space-y-3">
          <div css={css`
          margin-bottom: 10px;
        `}>
            {" "}
            <Link
              to="/submit-story-2024"
              className=" font-bench bg-accent inline-flex items-center justify-center px-6 py-1  text-white rounded-full relative"
            >
              SHARE YOUR STORY TO WIN{" "}
              <MdKeyboardArrowRight className="absolute right-0 mt-px mr-1" />
            </Link>
          </div>
          {config.map((link) => {
            return (
              <>
                {link.children ? (
                  <>
                    <div
                      className="font-bench uppercase text-xl"
                      onClick={() => {
                        setLocationIsOpen(true);
                      }}
                    >
                      {" "}
                      {link.label}
                    </div>
                  </>
                ) : (
                  <Link
                    className="font-bench uppercase text-xl"
                    to={link.value}
                  >
                    {link.label}
                  </Link>
                )}
              </>
            );
          })}
        </div>
      </motion.div>
    </>
  );
}

const Winalot = () => {
  const { winalot } = useStaticQuery(graphql`
    {
      winalot: file(relativePath: { regex: "/winalot-logo-navmob/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="w-24 absolute right-0 top-0 mr-3">
      <GatsbyImage fluid={winalot.childImageSharp.fluid} />
    </div>
  );
};
