import { graphql, Link, useStaticQuery } from "gatsby";

import React from "react";
import { css } from "@emotion/core";
import "../../scss/main.scss";
import GatsbyImage from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";
export default function TopBar() {
  const { immediate, winalot } = useStaticQuery(graphql`
    {
      immediate: file(relativePath: { regex: "/im-logo-2024.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      winalot: file(relativePath: { regex: "/winalot-logo-jun20.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <div
        className="lg:hidden"
        css={css`
          background-color: #f4f4f4;
        `}
      >
        <div className="container text-center uppercase tracking-widest font-sans text-sm ">
          advertisement feature
        </div>
      </div>
      <div className="bg-primary border-b-8 border-accent">
        <div className="container">
          <div
            className="grid gap-4 items-center"
            css={css`
              /* @md */
              grid-template: min-content / 112px 1fr 119px;

              @media (max-width: 768px) {
                grid-template: min-content / 86px 1fr 92px;
              }
              @media (max-width: 355px) {
                grid-template: min-content / 58px 1fr 92px;
              }
            `}
          >
            <OutboundLink href="https://www.immediate.co.uk/" target="_blank">
              <div className="text-center">
                <GatsbyImage fluid={immediate.childImageSharp.fluid} />
              </div>
            </OutboundLink>
            <div className="text-center text-white font-slab md:text-3xl lg:text-4xl uppercase leading-none font-bold">
              Great <br className="md:hidden" />
              British Dogs
            </div>
            <div className="-mb-2 relative">
              <div
                className="hidden lg:block"
                css={css`
                  position: absolute;
                  right: 100%;
                  top: calc(50% - 0.7rem);
                  color: #ffffff;
                  font-family: "Source Sans Pro";
                  font-size: 9px;
                  font-weight: 400;
                  letter-spacing: 1.5px;
                  text-transform: uppercase;
                  width: 146px;
                `}
              >
                ADVERTISEMENT FEATURE
              </div>
              <OutboundLink
                href="https://bit.ly/3eRimzY"
                target="_blank"
              >
                <GatsbyImage fluid={winalot.childImageSharp.fluid} />
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
