import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function CloseArrow({ onClick }) {
  return (
    <div
      onClick={onClick}
      className="w-10 h-10  flex items-center cursor-pointer"
    >
      <IoIosArrowRoundBack className="w-8 h-8" />
    </div>
  );
}
