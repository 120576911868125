import React, { useState } from "react";
export const Context = React.createContext({ isOpen: false });
export default function Navigation({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Context.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </Context.Provider>
  );
}
